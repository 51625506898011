import React from "react";
import { PageWrapper } from "~components/Core";
import HeroSection from '~sections/portfolio-details/TradingInsightApp/Hero'
import HeaderButton from '~sections/portfolio-details/TradingInsightApp/Header'
import ServicesSection from '~sections/app/Services'
import AboutSection from '~sections/portfolio-details/TradingInsightApp/About'
import ContentSectionOne from '~sections/portfolio-details/TradingInsightApp/ContentOne'
import PricingSection from '~sections/portfolio-details/TradingInsightApp/Pricing'
import TestimonialSection from '~sections/app/Testimonial'
import PromoSection from '~sections/app/Promo'
import CtaSection from '~sections/portfolio-details/TradingInsightApp/Cta'
import FooterFour from '~sections/app/FooterFour'

import FooterFive from '~sections/agency/FooterFive'

import CtaSectionTwo from '~sections/it/Cta'


const header = {
    headerClasses: "site-header site-header--menu-end light-header site-header--sticky",
    containerFluid: false,
    buttonBlock: <HeaderButton className="d-none d-sm-flex" children="Free SEO Analysis" />,
}

export default function HomeApp() {
    return (
        <PageWrapper innerPage={true}>
            <HeroSection />
            {/* <ServicesSection /> */}
            <AboutSection />
            <ContentSectionOne />
            {/* <TestimonialSection />
            <PromoSection /> */}
            <CtaSection />
            <CtaSectionTwo />
            <FooterFive />
        </PageWrapper>
    )
}
