import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SectionTitle from './components/SectionTitle'
import Widget from './components/widget'
import { StaticImage as Img } from 'gatsby-plugin-image'
import About from './style'
export default function AboutSection() {
  return (
    <About backgroundColor="#f2f5fb">
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-6 col-lg-8 col-md-9 col-xs-10 text-center">
            <SectionTitle
              subTitle="About Trading Insight"
              title="Better Insights for your trading decision"
              titleProps={{ mb: "50px" }}
              subTitleProps={{ mb: "15px" }}
            />
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col className="col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-xs-9 order-2 order-lg-1">
            <Widget directionXS="row-reverse" title="Real Time Trading Analysis" icon="fas fa-search" text="Technical analysis based signal, Smart Money, ICT, Fundemetal Signals " />
            <Widget directionXS="row-reverse" title="Fundemetal Analysis" icon="fas fa-search-dollar" text="Economy Indicator comparison, Fundemetal Bais " />
            <Widget directionXS="row-reverse" title="Risk Management" icon="fas fa-calendar-minus" text="Position Calculator, Trade Journal " />
          </Col>
          <Col xs="12" className="col-xxl-6 col-lg-4 col-md-8 col-xs-9 order-1 order-lg-2 text-center">
            <About.ImageContent>
              <Img src="../../../../assets/image/portfolio/trading-insight-app/3.png" alt="content" layout="fullWidth" placeholder="blurred" />
              <About.Shape>
                <Img src="../../../../assets/image/home-app/green-shape.png" alt="content" layout="fullWidth" placeholder="blurred" />
              </About.Shape>
            </About.ImageContent>
          </Col>
          <Col className="col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-xs-9 order-3">
            <Widget directionXS="row" title="News" icon="fas fa-newspaper" text="Fundemetal News fews, Currency based news timeline " />
            <Widget directionXS="row" title="Comparison" icon="fas fa-retweet" text="Technical comparizon, Fundemetal comparizon, Sentimental Analysis " />
            <Widget directionXS="row" title="Alerts" icon="fa fa-bell" text="Push notifications, Tradingjng signals, News alerts " />
          </Col>
        </Row>
      </Container>
    </About>

  )
}